























import {
  Component, Vue,
} from 'vue-property-decorator';

import Arrow from '@/assets/icons/Arrow.vue';

import { OPTIONS_SELECT } from '../../constants/filter';

@Component({
  components: {
    Arrow,
  },
})
export default class SelectMyRedaction extends Vue {
  private options = OPTIONS_SELECT;

  setChangeSelect(e: any) {
    this.$emit('change-select', Number(e.target.value));
  }
}
