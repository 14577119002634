import {
  STATUS_REDACTION_CORRECTED_NUMBER,
  STATUS_REDACTION_PENDENT_NUMBER,
  STATUS_REDACTION_REJECTED_NUMBER,
  STATUS_REDACTION_CHECKING_NUMBER,
} from '../../../constants/StatusRedaction';

export const OPTIONS_SELECT = [
  { id: 0, title: 'Todas as redações', selected: true },
  { id: 1, title: 'Redações com tempo', selected: false },
  { id: 2, title: 'Redações sem tempo', selected: false },
];

export const UPDATED_TAB_MENU = [
  { title: 'Aguardando Correção', slug: 'waiting', active: true },
  { title: 'Corrigidas', slug: 'corrected', active: false },
  { title: 'Rejeitadas', slug: 'rejected', active: false },
];

export const STATUS_MY_REDACTION = {
  [UPDATED_TAB_MENU[0].slug]: [STATUS_REDACTION_PENDENT_NUMBER, STATUS_REDACTION_CHECKING_NUMBER],
  [UPDATED_TAB_MENU[1].slug]: [STATUS_REDACTION_CORRECTED_NUMBER],
  [UPDATED_TAB_MENU[2].slug]: [STATUS_REDACTION_REJECTED_NUMBER],
};

export const FILTER_TIME = {
  [OPTIONS_SELECT[0].id]: null,
  [OPTIONS_SELECT[1].id]: true,
  [OPTIONS_SELECT[2].id]: false,
};
