
























































import { Component, Vue } from 'vue-property-decorator';

import PageHeader from '@/components/PageHeader/PageHeader.vue';
import BoxContainer from '@/components/BoxContainer/BoxContainer.vue';
import Tabs, { TabsMenuInterface } from '@/components/Tabs/Tabs.vue';
import ContainerFluid from '@/components/ContainerFluid/ContainerFluid.vue';
import Footer from '@/components/Footer/Footer.vue';
import ModalRedactionRejected from '@/components/Redaction/Modals/ModalRedactionRejected.vue';
import FeedbackUser from '@/components/FeedbackUser/index.vue';

import ContainerSectionMyRedactions from './components/ContainerSectionMyRedactions/ContainerSectionMyRedactions.vue';
import SelectMyRedaction from './components/SelectMyRedaction/index.vue';

import RedactionService from '@/services/Redaction/RedactionService';

import { UPDATED_TAB_MENU, STATUS_MY_REDACTION, FILTER_TIME } from './constants/filter';

import { MyRedactions as IMyRedactions } from './IMyRedaction';
import ActiveModal from '@/share/Util/ActiveModal';

@Component({
  components: {
    PageHeader,
    ContainerFluid,
    BoxContainer,
    Tabs,
    SelectMyRedaction,
    ContainerSectionMyRedactions,
    FeedbackUser,
    ModalRedactionRejected,
    Footer,
  },
})
export default class Redaction extends Vue {
  private isLoadingRedactions = true;

  private DATA_REQUEST = {
    page_redaction: 0,
    total_redaction: 10,
    last_page_redaction: 0,
    status: STATUS_MY_REDACTION.waiting,
    withTime: FILTER_TIME[0],
  }

  private list_redaction: Array<IMyRedactions> = [];

  private messageRedactionRejected: string | undefined = '';

  private updatedTabMenu: Array<TabsMenuInterface> = UPDATED_TAB_MENU;

  private RedactionService = new RedactionService();
  private setModal = new ActiveModal();

  created() {
    this.getListRedactions();
  }

  mounted() {
    this.setBreadCrumbs();
  }

  get isMobile() {
    return this.$responsive.isMobile;
  }

  get hasMoreRedaction() {
    return this.DATA_REQUEST.page_redaction < this.DATA_REQUEST.last_page_redaction;
  }

  get showFeedbackUser() {
    return !this.list_redaction.length && !this.isLoadingRedactions;
  }

  async getListRedactions() {
    try {
      this.isLoadingRedactions = true;

      const response = await this.RedactionService.getMyRedaction({
        page: this.DATA_REQUEST.page_redaction,
        status: this.DATA_REQUEST.status,
        limit: this.DATA_REQUEST.total_redaction,
        ...(this.DATA_REQUEST.withTime !== null && { withTime: this.DATA_REQUEST.withTime }),
      });

      this.list_redaction.push(...response?.redactions);
      this.DATA_REQUEST.last_page_redaction = this.setLastPageRedaction(response?.totalRedactions);

      this.moveScrollList('listRedaction');
    } catch (error) {
      console.error(error);
    } finally {
      this.isLoadingRedactions = false;
    }
  }

  async nextPageRedactions() {
    this.DATA_REQUEST.page_redaction += 1;

    await this.getListRedactions();
  }

  setLastPageRedaction(totalRedaction: number) {
    return Math.trunc(totalRedaction / this.DATA_REQUEST.total_redaction);
  }

  async changeTabActive(index: number, item: Array<TabsMenuInterface>) {
    this.updatedTabMenu = item;

    const slug = item[index].slug || '';
    this.DATA_REQUEST.status = STATUS_MY_REDACTION[slug];

    this.resetData();
    await this.getListRedactions();
  }

  async setChangeSelect(optionsId: number) {
    this.DATA_REQUEST.withTime = FILTER_TIME[optionsId];

    this.resetData();
    await this.getListRedactions();
  }

  resetData() {
    this.list_redaction = [];
    this.DATA_REQUEST.page_redaction = 0;
    this.DATA_REQUEST.last_page_redaction = 0;
  }

  showModalRedactionRejected(idMyRedaction: number) {
    this.messageRedactionRejected = this.list_redaction.find((redaction: IMyRedactions) => redaction.id === idMyRedaction)?.rejection_cause;
    this.setModal.activeModal('modalRedactionRejected');
  }

  goToRedaction() {
    this.$router.push({
      name: 'Redaction',
    });
  }

  moveScrollList(refList: string) {
    const list: any = this.$refs[refList];

    if (list?.$refs[refList] && list?.$refs[refList].scrollHeight) {
      this.$nextTick(() => {
        list.$refs[refList].scrollTop = list?.$refs[refList].scrollHeight;
      });
    }
  }

  setBreadCrumbs() {
    this.$breadcrumb.set([
      { title: 'Redação ', to: '/redacao' },
      { title: 'Minhas redações ', to: null },
    ]);
  }
}
